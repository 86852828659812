import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/interiors/img--interiors-7.jpg").default, caption:"2 BEDROOM TOWNHOUSE SECTION", x:635, y:295, width:570, height:285 },
    { src:require("../images/interiors/img--interiors-8.jpg").default, caption:"3 BEDROOM TOWNHOUSE SECTION", x:1225, y:295, width:590, height:285 },
    { src:require("../images/interiors/img--interiors-9.jpg").default, caption:"4 BEDROOM TOWNHOUSE SECTION", x:890, y:600, width:710, height:285 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/interiors/bkg--interiors-3.jpg").default} />
        </div>
    )
}