import coordData from "./coord-data";


const plans:any = {
    '0121': [121],
    '0122': [122],
    '0123': [123],
    '0124': [124], 
    '0125': [125],
    '0126': [126],
    '0127': [127],
    '0128': [128],
    '0129': [129],
    '0130': [130],
    '0131': [131],
    '0132': [132],
    '0221': [221],
    '0222': [222],
    '0223': [223],
    '0224': [224],
    '0225': [225],
    '0328': [328],
    '0329': [329],
    '0330': [330],
    '0331': [331],
    '0332': [332],
    '0333': [333],
    '0334': [334],
    '0335': [335],
    '0336': [336],
    '0337': [337],
    '0338': [338],
    '0339': [339],
    '0340': [340],
    '0341': [341],
    '0342': [342],
    '0343': [343],
    '0428_0534_0634_0734_0833_0933': [428, 534, 634, 734, 833, 933],
    '0429': [429],
    '0430': [430],
    '0431_0537_0637_0737_0836_0936_1029_1122': [431, 537, 637, 737, 836, 936, 1029, 1122],
    '0432_0538_0638_0738_0837_0937_1030_1123': [432, 538, 638, 738, 837, 937, 1030, 1123],
    '0433_0539_0639_0739_0838_0938_1031_1124': [433, 539, 639, 739, 838, 938, 1031, 1124],
    '0434': [434],
    '0435': [435],
    '0436': [436],
    '0437_0543': [437, 543],
    '0438': [438],
    '0439': [439],
    '0440': [440],
    '0441': [441],
    '0442_0548': [442, 548],
    '0443_0549': [443, 549],
    '0444': [444],
    '0445': [445],
    '0535_635_735_834': [535, 635, 735, 834],
    '0536_0636_0736_0835_0935_1028_1121': [536, 636, 736, 835, 935, 1028, 1121],
    '0540_0640_0740_0839_0939_1032_1125': [540, 640, 740, 839, 939, 1032, 1125],
    '0541_0741_0940': [541, 741, 940],
    '0542': [542],
    '0544': [544],
    '0545_0645_0745_0844': [545, 645, 745, 844],
    '0546_0646_0746_0845': [546, 646, 746, 845],
    '0547': [547],
    '0550': [550],
    '0551': [551],
    '0641': [641],
    '0642': [642],
    '0643': [643],
    '0644': [644],
    '0647': [647],
    '0648': [648],
    '0649': [649],
    '0650': [650],
    '0651': [651],
    '0742_0841': [742, 841],
    '0743': [743],
    '0744_0843': [744, 843],
    '0747': [747],
    '0748_0847': [748, 847],
    '0749_0848_0943': [749, 848, 943],
    '0840': [840],
    '0842': [842],
    '0846': [846],
    '0934_1027_1120': [934, 1027, 1120],
    '0941': [941],
    '0942': [942],
    '0944': [944],
    '0945': [945],
    '1026_1119': [1026, 1119],
    '1217': [1217],
    '1218_1318': [1218, 1318],
    '1219': [1219],
    '1220': [1220],
    '1317': [1317],
    '1319': [1319],
    '1320': [1320],
    '1417': [1417],
    '1418': [1418],
    '1419': [1419],

}


function getPlan(apartmentId:string){
    var planKeys = Object.keys(plans);
    for(var k=0; k < planKeys.length; k++){
        var plan:any = planKeys[k];
        var apartments:number[] = plans[plan];
        var id = parseInt(apartmentId);
        if(apartments.indexOf(id) != -1){
            return plan;
        }
    }
    return null;
}


function getPDF(planId:string|null){
    if(planId == '4444'
    || planId == '4445'
    || planId == '4446'){
        return `SEK25321_Lumia_FloorplanTemplate_Penthouse_V7_FA_LR_${planId}.pdf`
    }else if(planId && planId.indexOf("P")==0){
        return `SEK25321_Lumia_FloorplanTemplate_Standard_V8_FA_LR_${planId}.pdf`
    }else{
        return planId + ".pdf";
    }
}


export default {
    getPlan,
    getPDF
}