import { apiFetch } from "../helper/api";

export type SalesData = Record<string, {
    bed: string,
    price: string,
    status: "" | "Available" | "Sold" | "Conditional Exchange" | "Reserved", // Any others?
    saleStatus: "Stock" | "Contract Exchange" | "Held by Agent" | "Held by Vendor" | "Contract Issued" | "Conditional Exchange" | "Reserved" | "Holding Deposit", // Any others?
    max:boolean,
}>;


export async function fetchSalesData(username:string, password:string){
    const responseA = await apiFetch("https://cms.orchardsveue.com.au/api/app-get-live-data-json-veueparkside.php", {
        user_name: username,
        password,
        page: 0,
    })
    const dataA:any = await responseA.json();

    const responseB = await apiFetch("https://cms.orchardsveue.com.au/api/app-get-live-data-json-veueparkside.php", {
        user_name: username,
        password,
        page: 1,
    })
    const dataB:any = await responseB.json();

    const data = {
        ...dataA,
        ...dataB,
    //    ...dataC,
    }

    const result:SalesData = {};
    Object.keys(data).forEach(function(key){
        const unitNumber = parseInt(key.split(" ").pop()!)
        result[unitNumber] = data[key];
    })

    return result;
}