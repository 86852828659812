import { useState } from 'react';
import ImageLoader from '../components/ImageLoader'
import Lightbox from '../components/Lightbox';
import ThumbnailPicker from '../components/ThumbnailPicker'


const items = [
    { src:require("../images/interiors/img--interiors-1.jpg").default, caption:"LIVING & KITCHEN", x:635, y:200, width:692, height:471 },
    { src:require("../images/interiors/img--interiors-2.jpg").default, caption:"LIVING & TERRACE", x:1344, y:200, width:525, height:224 },
    { src:require("../images/interiors/img--interiors-3.jpg").default, caption:"TERRACE", x:1344, y:446, width:525, height:224 },
    { src:require("../images/interiors/img--interiors-4.jpg").default, caption:"KITCHEN & DINING", x:634, y:692, width:452, height:288 },
    { src:require("../images/interiors/img--interiors-5.jpg").default, caption:"KITCHEN", x:1075, y:692, width:502, height:288 },
    { src:require("../images/interiors/img--interiors-6.jpg").default, caption:"PANTRY DETAIL", x:1575, y:692, width:345, height:288 },
]


export default function(){
    const [selection, setSelection] = useState<string|null>(null);

    return (
        <div>
            <ThumbnailPicker onSelected={setSelection} items={items}/>
            <Lightbox items={items} selection={selection} onSelected={setSelection} carousel={true}/>
            <ImageLoader src={require("../images/interiors/bkg--interiors-1.jpg").default} />
        </div>
    )
}