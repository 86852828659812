import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import isIOS from '../helper/is-ios'
import useOutsideClick from "@rooks/use-outside-click";
import { Context } from '../store/store';


function Logo(){
   return (
        <div className="Logo">
            <Link to="/">
                <img src={require("../images/interface/VEUE_Stacked Logo_RGB_White@2x.png").default} style={{ width: '40%', height: 'auto' }}/>
            </Link>
        </div>
    )
}


function LeftLinks(){
    const {state, actions} = useContext(Context);
    
    return (
        <div className="LeftLinks">
            { state.user.username && state.user.password &&
                <>
                    <NavLink name='LOCATION' path="/location">
                        <NavLink name="LIFESTYLE" path="/location/lifestyle" />
                        <NavLink name="AERIAL MAP" path="/location/aeriel-map" />
                        <NavLink name="LOCATION MAP" path="/location/location-map" />
                    </NavLink>
                    <NavLink name='MASTERPLAN' path='/masterplan' />
                    <NavLink name='DESIGN' path='/design' />
                    <NavLink name='INTERIORS' path='/interiors'>
                        <NavLink name="APARTMENTS" path='/interiors/apartments'/>
                        <NavLink name="TOWNHOUSES" path='/interiors/townhouses'/>
                        <NavLink name="COLOUR SCHEMES" path='/interiors/color-schemes'/>
                    </NavLink>
                    {/* <NavLink name='VIEWS' path='/views' /> */}

                    <NavLink name='FLOOR PLANS' path='/floor-plans' />
                    <NavLink name='TEAM' path='/team' />
                    <NavLink name='ENQUIRE' path='/enquire' />
                </>
            }
        </div>
    )
}

function RightLinks(){
    const {state, actions} = useContext(Context);
    
    return (
        <div className="RightLinks">
            { state.user.username && state.user.password
            ? <NavLink name="LOGOUT" path="/agent-logout" />
            : <NavLink name="AGENT LOGIN" path="/agent-login" />
            }
        </div>
    )
}


function NavLink({name, path, children}:{name:string, path:string, children?:React.ReactNode}){
    const ref:MutableRefObject<HTMLDivElement> = useRef() as MutableRefObject<HTMLDivElement>;
    const [focused, setFocused] = useState(false);
    const location = useLocation();
    const active = location.pathname.indexOf(path) !== -1;
    const exact = location.pathname === path;
    
    useOutsideClick(ref, clickedOutside);
    
    function clickedOutside(){
        if(focused && isIOS) blur();
    }

    function focus(){
        if(isIOS) setFocused(true);
    }

    function blur(){
        if(isIOS && focused) setFocused(false);
    }

    useEffect(() => {
    }, [focused])
    
    
    /*
    function blur(){
        if (document.activeElement !== document.body) (document.activeElement as any).blur(); 
    }
    */
    
    function Menu(){
        return (
            <div className="Menu">
                { children }
            </div>
        )
    }


    function NavLinkMenu(){
        return (
            <div className="NavLinkMenu" ref={ref}>
                <div className="NavLinkMenuName">
                    {name}
                </div>
            </div>)
    }


    function goToPath(){
        window.location.href = "#" + path;
    }

    return (
        <div ref={ref} onClick={focus} className="NavLink" data-ios={isIOS} data-focus={focused} data-active={active} data-exact={exact}>
            { !children && <div className="Link" onClick={goToPath}>{name}</div> }
            { children && <NavLinkMenu /> }
            { children && <Menu />}
        </div>
    )
}


export default function (){
    return (
        <div className="Nav">
            <Logo />
            <LeftLinks />    
            <RightLinks />
        </div>
    )
}
