type Props<N extends string> = {
    name:N,
    placeholder:string,
    value:string,
    errors:string[]|null|undefined,
    type:'text'|'email'|'tel'|'password',
    onChange: (name:N, value:string) => void,
}


export default function<N extends string>({name, placeholder, value, errors, type, onChange}:Props<N>){
    function onInputChange(e:{target:{value:string}}){
        onChange(name, e.target.value);
    }

    return (
        <div className="Field" id={`field-${name}`} data-has-errors={!!errors}>
            <input autoCapitalize="off" className="Input" name={name} type={type} value={value} placeholder={placeholder} spellCheck="false" onChange={onInputChange}/>
        </div>
    )
}
