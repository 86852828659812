import { MutableRefObject, useEffect, useRef, useState } from "react";
import ImageLoader from "./ImageLoader";

type Props<T> = {
    selected:T,
    onSelection:(value:T)=>void,
    options:{
        label:string,
        value:T,
        num?:number
    }[],
}


export default function<T>({selected, options, onSelection}:Props<T>){
    const topRef:MutableRefObject<HTMLDivElement> = useRef() as MutableRefObject<HTMLDivElement>;
    
    const [state, setState] = useState({
        mouseDown:false,
    })

    useEffect(() => {
        function handler(){
            setState({
                ...state,
                mouseDown: false,
            })
        }
        window.addEventListener("mouseup", handler);
        return () => {
            window.removeEventListener("mouseup", handler);
        }
    })

    function handleMove(y:number){
        /*
        if(isDown===false || state.isDown===false){
            setState({
                ...state,
                isDown:isDown!
            })
        }else{
            const rect = refA.current!.getBoundingClientRect();
            const relX = x - rect.x;
            var percent = relX / rect.width;
            if(percent < 0) percent = 0;
            if(percent > 1) percent = 1;
            setState({
                ...state,
                percent,
                isDown,
            });
        }
        */

        var rect = topRef.current.getBoundingClientRect();
        var top = rect.top;
        var bottom = rect.bottom;
        
        y -= top;
        var percent = y / (bottom-top);
        if(percent < 0) percent = 0;
        if(percent > 1) percent = 1;

        var index = Math.floor(options.length*percent);
        var option = options[index];
        if(option) if(option.value != selected) onItemClicked(option)();
    }

    function onItemClicked(option:Props<T>['options'][number]){
        return function(){
            onSelection(option.value);
        }
    }

    function onMouseDown(){
        setState({
            ...state,
            mouseDown:true,
        })
    }

    function onMouseUp(){
        setState({
            ...state,
            mouseDown:false,
        })
    }


    return (
        <div className="LevelSelector" ref={topRef} onTouchMove={e => handleMove(e.touches[0].pageY)} onTouchStart={onMouseDown} onTouchEnd={onMouseUp} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
            <div className="LevelSelectorLine">
                <svg height="900px" width="200">
                    <line x1="120" y1="10" x2="120" y2="75%" stroke="white" strokeWidth="1" />
                </svg>
            </div>
        {


            options.map(option => (
                <div className="LevelSelectorItem" onClick={onItemClicked(option)} onMouseOver={e => state.mouseDown && onItemClicked(option)()}>
                    <div className="LevelSelectorLabel">{option.label.toUpperCase()}</div>
                    <div className="LevelSelectorIcon">
                        <svg height="40" width="40">
                            <circle cx="20" cy="20" r="16" stroke="white" strokeWidth="1.5" fill="#3b5f52" />
                            { option.value===selected && 
                                <circle cx="20" cy="20" r="10" fill="white" />
                            }
                        </svg>
                    </div>
                    { (option.num!=undefined && option.num!=0) && <div className="LevelSelectorNumber">
                        <ImageLoader src={require('../images/interface/icon--apt-numbers.png').default} />
                        <div style={{ paddingTop: '5px'}}><span className='TearDropLabel'>{option.num}</span></div> 
                    </div> }
                </div>
            ))    
        }
        </div>
    )
}