import ImageLoader from './ImageLoader'
import SidePanel from './SidePanel';

type Props = {
    onSelected:(id:string)=>void;
    selection: string|null,
    items:{
        icon:string,
        iconHover:string,
        name:string,
        overlay:string,
        id:string,
    }[]
}


const NavMasterplanItem = (selection:string|null, onSelected:(id:string)=>void) => (item:Props['items'][number]) => {
    const selected = selection === item.id;
    const onClick = () => onSelected(item.id)

    return (
        <div className="NavMasterplanItem" data-selected={selected} onClick={onClick} key={item.id}>
            <span className="NavMasterplanItemIcon"><ImageLoader src={item.icon}/><ImageLoader src={item.iconHover}/></span><span className="NavMasterplanItemLabel">{item.name}</span>
        </div>
    )
}


export default function({items, selection, onSelected}:Props){
    return (
        <SidePanel width={390} justifyContent="center">
            { items.map(NavMasterplanItem(selection, onSelected)) }
        </SidePanel>
    )
}